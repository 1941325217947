<template>
    <div class="client-profile">
        <personal-accounts-page
                :page-title="pageTitle"
                :titles="titles"
                :zfl-titles="zfl"
                :cards="getLS_LIST"
                @download="download"
        />
    </div>
</template>

<script>
    import PersonalAccountsPage from "../../../components/pages/cabinet/documents/PersonalAccountsPage";
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: "DocumentsPersonalAccounts",
        components: {PersonalAccountsPage},
        watch: {
            docChanged() {
                if (this.changedDocAlias === "qst-reg_person" || this.changedDocAlias === "appl-open_pers_account")
                    this.GET_LS_LIST()
            },
        },
        computed: {
            ...mapGetters("DocumentsModule", ["getLS_LIST"]),
            ...mapGetters("AutoUpdateModule", ["docChanged", "changedDocAlias"]),
        },
        data() {
            return {
                documentKey: 0,
                pageTitle: "Лицевые счета",
                titles: [
                    {name: "fond_name", value: "Наименование фонда:"},
                    {name: "doc_account_open_date", value: "Дата заявления об открытии лицевого счета:"},
                    {name: "doc_account_open_id", value: "Заявление:"},
                    {name: "status", value: "Статус заявления:"},
                    {name: "ls_open_date", value: "Дата открытия лицевого счета:"},
                    {name: "ls_number", value: "Номер лицевого счета:"},
                    {name: "account_open_notification", value: "Уведомление об открытии ЛС:"},
                    {name: "personal_notification", value: "Извещение:"},

                ],
              zfl: [
                {name: "fond_name", value: "Наименование фонда:"},
                {name: "date_sign", value: "Дата подписания:"},
                {name: "doc_reg_person_id", value: "Анкета зарегистрированного лица:"},
                {name: "status", value: "Статус заявления:"},
                {name: "spec_reg", value: "СпецРег:"},
                {name: "action", value: "Событие:"},
              ],
            }
        },
        methods: {
            ...mapActions("DocumentsModule", ["GET_LS_LIST", "DOWNLOAD_DOCUMENT"]),
            ...mapActions("regModule", ["DOWNLOAD_FILE"]),
            download(link) {
                console.log(link)
                if (!link?.id) {
                    this.$toast.error('Документ не найден')
                } else {
                    let name = link.name === 'account_open_notification' ? "Уведомление об открытии ЛС.pdf"
                        : link.name === 'doc_account_open_id' ? "Заявление об открытии лицевого счета.pdf"
                            : link.name === 'doc_reg_person_id' ? "Анкета зарегистрированного лица.pdf"
                            : link.origin_name ? link.text_name ? link.text_name + link.origin_name.split(".")[1] : link.origin_name : null
                    if (name && (link.name === 'account_open_notification' || link.origin_name)) {
                        this.DOWNLOAD_FILE({id: link.id, name: name})
                    }
                    if (name && (link.name === 'doc_account_open_id' || link.name === 'doc_reg_person_id')) {
                        this.DOWNLOAD_DOCUMENT({id: link.id, name: name})
                    }
                }
            },
        },
        mounted() {
            this.GET_LS_LIST()
        }
    }
</script>

<style scoped lang="scss">
</style>